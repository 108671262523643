import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { type } from "../../../services/Constants";
import { dcsgetOrderChangeHistoryLogs } from "../../../services/data/Helpers";

export interface historyTableDataType {
  date: string;
  time: string;
  user: string;
  event: string;
  page: string;
  comment: string;
}

export interface ReponseDataType {
  dateTime: string;
  user: string;
  event: string;
  page: string;
  comment: string;
}
export interface Props {
  orderId: number;
}
function ChangeHistory(props: Props) {
  let { orderId } = props;
  const [historyTableData, setHistoryTableData] = useState<
    historyTableDataType[]
  >([]);

  const sortingHandler = (response: ReponseDataType[]) => {
    const sortedData = response.sort((a: any, b: any) => {
      const dateA = new Date(a.dateTime);
      const dateB = new Date(b.dateTime);
      return dateB.getTime() - dateA.getTime();
    });

    const formatedData = sortedData.map((obj: any) => {
      const dateTime = new Date(obj.dateTime);
      const date = dateTime.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      const time = dateTime.toLocaleTimeString(["de-DE"], {
        hour: "2-digit",
        minute: "2-digit",
      });
      return {
        date,
        time,
        user: obj.user,
        page: obj.page,
        event: obj.event,
        comment: obj.comment,
      };
    });
    setHistoryTableData(formatedData);
  };

  useEffect(() => {
    const init = async () => {
      const apiResponse = await dcsgetOrderChangeHistoryLogs(orderId)
      if(apiResponse.status===type.SUCCESS){
        sortingHandler(apiResponse.response);
      }

    };
    init();
  }, []);
  const { t } = useTranslation();
  return (
    <div className="change-history-wrapper">
      <div className="history-title">
        <PorscheFont className="dcs-section-heading">
          {t("Change History")}
        </PorscheFont>
      </div>
      <div className="order-chnage-history-wrapper">
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className="date-table-cell heading-table-cell">
                  <PorscheFont
                    className="table-heading"
                  >
                    {t("Date")}
                  </PorscheFont>
                </TableCell>

                <TableCell className="time-table-cell heading-table-cell">
                  <PorscheFont
                    className="table-heading"
                  >
                    {t("Time")}
                  </PorscheFont>
                </TableCell>

                <TableCell className="user-table-cell heading-table-cell">
                  <PorscheFont
                    className="table-heading"
                  >
                    {t("User")}
                  </PorscheFont>
                </TableCell>

                <TableCell className="event-table-cell heading-table-cell">
                  <PorscheFont
                    className="table-heading"
                  >
                    {t("Status")}
                  </PorscheFont>
                </TableCell>

                <TableCell className="page-table-cell heading-table-cell">
                  <PorscheFont
                    className="table-heading"
                  >
                    {t("Page")}
                  </PorscheFont>
                </TableCell>

                <TableCell className="comment-table-cell heading-table-cell">
                  <PorscheFont
                    className="table-heading"
                  >
                    {t("Comment")}
                  </PorscheFont>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyTableData.map((item, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell className="date-table-cell data-table-cell">
                      <PorscheFont className="changeHistory-rowitem">
                        {item.date}
                      </PorscheFont>
                    </TableCell>
                    <TableCell className="time-table-cell data-table-cell">
                      <PorscheFont className="changeHistory-rowitem">
                        {item.time}
                      </PorscheFont>
                    </TableCell>
                    <TableCell className="user-table-cell data-table-cell">
                      <PorscheFont className="changeHistory-rowitem">
                        {item.user}
                      </PorscheFont>
                    </TableCell>
                    <TableCell className="event-table-cell data-table-cell">
                      <PorscheFont className="changeHistory-rowitem">
                        {t(item.event)}
                      </PorscheFont>
                    </TableCell>
                    <TableCell className="page-table-cell data-table-cell">
                      <PorscheFont className="changeHistory-rowitem">
                        {t(item.page)}
                      </PorscheFont>
                    </TableCell>
                    <TableCell className="comment-table-cell data-table-cell">
                      <PorscheFont className="changeHistory-rowitem">
                        {t(item.comment)}
                      </PorscheFont>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default ChangeHistory;
