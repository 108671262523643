import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Stack } from "@mui/material";

import { PText } from "@porsche-design-system/components-react";
import { PorscheFont } from "../../../assets/styles/CustomStyles";
import { Constant } from "../../../configs/Constants";
import { OrderContext } from "../../../contexts/OrderContext";
import { getAmountNet } from "../../../helpers/calculations";
import getSessionStorage from "../../../helpers/getSessionStorage";
import draftValueHandler from "../../../helpers/sessionDraftValueHandler";
import {
  AdditionalCostsPriceDTO,
  CalculationDraftDTO,
  CommissionSharingDTO,
  DiscountNetPriceDTO,
  KickbackNetPriceDTO,
  TradeInPriceDTO,
  VehiclePriceDTO
} from "../../../interfaces/Calculation";
import { OrderDTO } from "../../../interfaces/Order";
import { UpsaleDetailsDTO, UpsaleItemDTO } from "../../../interfaces/UpsaleNew";
import { GetRvm } from "../../../interfaces/Variables";
import {
  formatCurrencyWithNoUnit,
  getPayementTypeName,
  getWorkshopServiceName,
  negativeValueHandler,
  showDashToValue,
} from "../../../mock/helper";
import { type } from "../../../services/Constants";
import {
  dcsgetOrderDetails,
  dcsgetOrderSummary,
  dcsgetRvm
} from "../../../services/data/Helpers";

export const NewCarPrint = React.forwardRef((props, ref: any) => {
  const { t } = useTranslation();  
  const orderValues = getSessionStorage(Constant.SingleOrdDetail);

  const orderContext = useContext(OrderContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderData, setOrderData] = useState<CalculationDraftDTO>(
    {} as CalculationDraftDTO
  );
  const [recalculationOrderData, setRecalculationOrderData] =
    useState<CalculationDraftDTO>({} as CalculationDraftDTO);
  const [recalVehiclePrices, setRecalVehiclePrices] =
    useState<Array<VehiclePriceDTO | null>>();
  const [recalDiscountNet, setRecalDiscountNet] =
    useState<Array<DiscountNetPriceDTO | null>>();
  const [recalKickbat, setRecalKickbat] =
    useState<Array<KickbackNetPriceDTO | null>>();
  const [recalAdditionalCost, setRecalAdditionalCost] =
    useState<Array<AdditionalCostsPriceDTO | null>>();
  const [recalTrandInPrices, setRecalTradeInPrices] =
    useState<Array<TradeInPriceDTO | null>>();
  const [recalCommissionShares, setRecalCommissionShares] =
    useState<Array<CommissionSharingDTO | null>>();
  const [recalUpsaleItems, setRecalUpsaleItems] =
    useState<Array<UpsaleItemDTO>>();

  const [order, setOrder] = useState<OrderDTO>({} as OrderDTO);
  const [rvm, setRvm] = useState<GetRvm>({} as GetRvm);
  const [parts, setParts] = useState<Array<UpsaleItemDTO>>([]);
  const mode = orderValues?.id ? orderValues?.id+'' :'' ;
  const draft = draftValueHandler(orderValues?.draft);
  const [upsalseTotalCommission, setUpsaleTotalCommission] = useState(0)

  const PDSTEQcommissionCalculation  = (upsItem:UpsaleItemDTO[])=>{
    let totalCommission = 0
upsItem.forEach((item)=>{
  if(item.commission>0){

    totalCommission = totalCommission + item.commission
  }
})
setUpsaleTotalCommission(totalCommission)
  }

  useEffect(() => {
    const init = async () => {
      const apiResponse =
        draft === "true"
          ? await dcsgetOrderDetails(true, parseInt(mode))
          : await dcsgetOrderSummary(parseInt(mode));
          if(apiResponse.status===type.SUCCESS){
            const preCalculationData = apiResponse.response?.calculation as CalculationDraftDTO;
          const recalCulationdata = apiResponse.response?.reCalculation as CalculationDraftDTO;
          const upsaleDetails = apiResponse?.response.upsale  as UpsaleDetailsDTO
          PDSTEQcommissionCalculation(upsaleDetails.upsaleItems)
          setOrderData(preCalculationData);
    
          const rearrangedVehiclePrices = preCalculationData?.vehiclePrices
            ?.map((item) => {
              const foundItem = recalCulationdata?.vehiclePrices?.find(
                (element) => element.displayLabel === item.displayLabel
              );
              return foundItem ? { ...foundItem } : null;
            })
            .filter((item) => item !== null);
          rearrangedVehiclePrices && setRecalVehiclePrices(rearrangedVehiclePrices);
          setRecalculationOrderData(
            apiResponse.response?.reCalculation
          );
    
          const rearrangedDiscountPrice = preCalculationData?.discountNetPrices
            ?.map((item) => {
              const foundItem = recalCulationdata?.discountNetPrices?.find(
                (element) =>
                  element.discountNetPriceType === item.discountNetPriceType
              );
              return foundItem ? { ...foundItem } : null;
            })
            .filter((item) => item !== null);
          setRecalDiscountNet(rearrangedDiscountPrice);
    
          const rearrangedKickback = preCalculationData?.kickbackNetPrices
            ?.map((item) => {
              const foundItem = recalCulationdata?.kickbackNetPrices?.find(
                (element) =>
                  element.kickbackNetPriceType === item.kickbackNetPriceType
              );
              return foundItem ? { ...foundItem } : null;
            })
            .filter((item) => item !== null);
          setRecalKickbat(rearrangedKickback);
    
          const rearrangedAdditionalDiscount =
            preCalculationData?.additionalCostsPrices
              ?.map((item) => {
                const foundItem = recalCulationdata?.additionalCostsPrices?.find(
                  (element) => element.type === item.type
                );
                return foundItem ? { ...foundItem } : null;
              })
              .filter((item) => item !== null);
          setRecalAdditionalCost(rearrangedAdditionalDiscount);
    
          const recalTradeInPrices = preCalculationData?.tradeInPrices
            ?.map((item) => {
              const foundItem = recalCulationdata?.tradeInPrices?.find(
                (element) => element.tradeInColumn === item.tradeInColumn
              );
              return foundItem ? { ...foundItem } : null;
            })
            .filter((item) => item !== null);
          setRecalTradeInPrices(recalTradeInPrices);
    
          const recalCommissionshare = preCalculationData?.commissionSharing
            ?.map((item) => {
              const foundItem = recalCulationdata?.commissionSharing?.find(
                (element) => element.sellerNames.id === item.sellerNames.id
              );
              return foundItem ? { ...foundItem } : null;
            })
            .filter((item) => item !== null);
          setRecalCommissionShares(recalCommissionshare);
    
          setOrder(apiResponse.response?.order);
          const rvmResponse = await dcsgetRvm();
          if(rvmResponse.status===type.SUCCESS){
            const rvmData = rvmResponse.response as Array<GetRvm>;
            const rvm = rvmData.filter(
              (x) =>
                x?.seriesDTO?.id ===
                apiResponse.response?.order?.vehicleDetails
                  ?.series?.id
            );
            if (rvm.length > 0 && rvm[0].grossProfit === true) {
              setRvm(rvm[0]);
            }
          }
          
    
          //Upsale details
          const workshop = apiResponse.response?.workshopCosts as UpsaleDetailsDTO;
          if (workshop?.upsaleItems?.length > 0) {
            setParts(parts);
          }
          }
    
    };
    init();
  }, []);
  const formateDate = (strDate: string): string => {
    if (!strDate) return "";
    const date = new Date(strDate).toISOString().split("T")[0];
    const formatDate = date.toString().split("-");
    return `${formatDate[2]}.${formatDate[1]}.${formatDate[0]}`;
  };

  const printGetAmountNet = (
    item: UpsaleItemDTO,
    service: boolean,
    i: number
  ) => {
    if (service) {
      if (
        item.serviceItems &&
        item.serviceItems.length > 0 &&
        item.serviceItems[i]
      ) {
        if (item.serviceItems[i].customerShare > 0) {
          const ret = item.serviceItems[i].services.priceBeforeCustomerNet
            ? item.serviceItems[i].services.priceBeforeCustomerNet
            : 0.0;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return (ret * item?.serviceItems?.[i].timeUnits!) / 100;
            } else {
              return 0.0;
            }
          } else {
            return ret;
          }
        } else {
          const ret1 = item.serviceItems[i].services.priceInternal
            ? item.serviceItems[i].services.priceInternal
            : 0.0;
          if (item.partType === "part") {
            if (item.serviceItems[i].timeUnits !== undefined) {
              return (ret1 * item?.serviceItems?.[i].timeUnits!) / 100;
            } else {
              return 0.0;
            }
          } else {
            return ret1;
          }
        }
      } else {
        return 0.0;
      }
    } else {
      if (item.customerShare > 0) {
        return item.part.priceBeforeCustomerNet
          ? item.part.priceBeforeCustomerNet
          : 0.0;
      } else {
        return item.part.priceInternal ? item.part.priceInternal : 0.0;
      }
    }
  };

  const getPercent = (
    item: UpsaleItemDTO,
    service: boolean,
    i: number,
    calType: string
  ) => {
    let disPercent = 0.0;
    let dis = 0.0;
    if (service) {
      const net = getAmountNet(item, service, i);
      if (
        item.serviceItems &&
        item.serviceItems.length > 0 &&
        item.serviceItems[i]
      ) {
        dis = net - item.serviceItems[i].customerShare;
      }
    } else {
      const net = getAmountNet(item, service, i);
      dis = net - item.customerShare;
    }
    if (
      calType === "re" &&
      recalVehiclePrices &&
      recalVehiclePrices[2]?.amountNet &&
      recalVehiclePrices[2].amountNet > 0
    ) {
      disPercent = (dis / recalVehiclePrices[2].amountNet) * 100;
    }

    if (
      calType === "pre" &&
      orderData &&
      orderData.vehiclePrices &&
      orderData.vehiclePrices[2].amountNet > 0
    ) {
      disPercent = (dis / orderData.vehiclePrices[2].amountNet) * 100;
    }

    return disPercent;
  };

  const checkBoldTextForDeviation = (value: any): string => {
    return value !== 0 ? "table-col-bold text-right" : "table-td";
  };

  const getSharePcPercent = () => {
    let rtpc = 0.0;
    let tpc = 0.0;
    if (
      recalculationOrderData?.tradeInPrices &&
      recalculationOrderData?.tradeInPrices.length > 0 &&
      orderData?.tradeInPrices! &&
      orderData?.tradeInPrices.length > 0
    ) {
      if (
        recalculationOrderData?.tradeInPrices[2].amountNet < 0 &&
        recalculationOrderData?.vehiclePrices &&
        recalculationOrderData?.vehiclePrices[2].amountNet > 0
      ) {
        rtpc =
          (recalculationOrderData?.tradeInPrices[3].amountNet /
            recalculationOrderData?.vehiclePrices[2].amountNet) *
          -1 *
          100;
      }

      if (
        orderData?.tradeInPrices[2].amountNet < 0 &&
        orderData?.vehiclePrices &&
        orderData?.vehiclePrices[2].amountNet > 0
      ) {
        tpc =
          (orderData?.tradeInPrices[3].amountNet /
            orderData?.vehiclePrices[2].amountNet) *
          -1 *
          100;
      }
    }

    return rtpc - tpc;
  };

  const calculateValueOfTradeInDiscount = (
    order: OrderDTO,
    type: string
  ): number | null => {
    let isPreCal = type === "precal";
    if (
      order?.tradeInDetails?.tradeInType === "Leasing_Redemption" ||
      order?.tradeInDetails?.tradeInType === "Financing_Redemption_Regular" ||
      order?.tradeInDetails?.tradeInType === "Financing_Redemption_Differential"
    ) {
      const tradeInAmountNet = isPreCal
        ? orderData?.tradeInPrices?.[3]?.amountNet || 0
        : recalTrandInPrices?.[3]?.amountNet || 0;
      const vehicleAmountNet = isPreCal
        ? orderData?.vehiclePrices?.[2]?.amountNet || 1
        : recalVehiclePrices?.[2]?.amountNet || 1;

      return (tradeInAmountNet / vehicleAmountNet) * -1 * 100 || 0.0;
    }
    return null;
  };

  const calculateDiscountPercentage = (usItem:UpsaleItemDTO)=>{ 
      
      let discoutPercenatge = 0.0
      
        //PriceBefore customer Net active logic
        if(usItem?.part.partDiscount == true){
            
            if (usItem.customerShare > 0 && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
              discoutPercenatge = ((usItem.part.priceBeforeCustomerNet - usItem.customerShare) / usItem.part.priceBeforeCustomerNet) * 100;
            } else if (usItem.part.priceInternal && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
              discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceBeforeCustomerNet) * 100;
            } 
        }
  
         //PriceInternal active logic
        if(usItem?.part.partDiscount == false){
          if (usItem.customerShare > 0 && usItem.part.priceInternal !== undefined && usItem.part.priceInternal > 0) {
            discoutPercenatge = ((usItem.part.priceInternal - usItem.customerShare) / usItem.part.priceInternal) * 100;
          } else if (usItem.part.priceBeforeCustomerNet && usItem.part.priceInternal !== undefined && usItem.part.priceInternal > 0) {
            discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceInternal) * 100;
          }
        };
  
        //when user in Draft edit mode and partDiscount is undefined
      if(usItem?.part.partDiscount == undefined){
        if (usItem.customerShare > 0 && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
          discoutPercenatge = ((usItem.part.priceBeforeCustomerNet - usItem.customerShare) / usItem.part.priceBeforeCustomerNet) * 100;
        } else if (usItem.part.priceInternal && usItem.part.priceBeforeCustomerNet !== undefined && usItem.part.priceBeforeCustomerNet > 0) {
          discoutPercenatge = (usItem.part.priceInternal / usItem.part.priceBeforeCustomerNet) * 100;
        }
      }
  
    


       return discoutPercenatge>0 ? discoutPercenatge:0
  
      }

  return (
    <>
      <div ref={ref}>
        <div className="print-header-container">
          <div className="header-left-side">
            <PorscheFont className="print-heading-name">
              3. {t("Calculation")} {t("New Car")}
            </PorscheFont>
            <div className="seller-info-label">
              <table>
                <tr>
                  <td className="table-label-td font-14">Seller</td>
                  <td className="font-14">
                    {showDashToValue(order?.orderCreator?.seller)}
                  </td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Comm No.</td>
                  <td className="font-14">
                    {showDashToValue(order?.vehicleDetails?.commissionNumber)}
                  </td>
                </tr>
                <tr>
                  <td className="table-label-td font-14">Act No.</td>
                  <td className="font-14">
                    {showDashToValue(order?.vehicleDetails?.actNumber)}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="header-right-side">
            <Stack direction="column" className="right-header-container">
              <PorscheFont className="order-number">
                {t("Order No. 20120230001")}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Creation date")}: {formateDate(order?.createdOn)}
              </PorscheFont>
              <PorscheFont className="order-date">
                {t("Last edited")}: {formateDate(order?.updatedOn)}
              </PorscheFont>
            </Stack>
          </div>
        </div>
        <div>
          <table className="table">
            <thead>
              <tr className="main-heading-row">
                <th colSpan={2}></th>
                <th colSpan={3} className="main-heading">
                  <PorscheFont>{t("Precalculation")}</PorscheFont>
                </th>
                <th colSpan={1} className="empty-space"></th>
                <th colSpan={3} className="main-heading">
                  <PorscheFont>{t("Recalculation")}</PorscheFont>
                </th>
                <th></th>
              </tr>
            </thead>
            {/* Vehicle Price start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top"
                >
                  <PorscheFont>{t("Vehicle Price")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {orderData?.vehiclePrices?.map((ele, index) => {
                return (
                  <tr key={index}>
                    <td colSpan={2} className="table-td-left">
                      <PorscheFont>{ele.displayLabel}</PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.amountGross)} €`}
                      </PorscheFont>
                    </td>
                    <td className="table-td"></td>
                    <td className="table-td"></td>
                    <td className="table-td">
                      <PorscheFont>
                        {recalVehiclePrices &&
                        recalVehiclePrices[index] &&
                        recalVehiclePrices[index]?.amountNet
                          ? `${formatCurrencyWithNoUnit(
                              recalVehiclePrices[index]?.amountNet!
                            )} €`
                          : "0,00 €"}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {recalVehiclePrices &&
                        recalVehiclePrices[index]?.amountGross
                          ? `${formatCurrencyWithNoUnit(
                              recalVehiclePrices[index]?.amountGross!
                            )} €`
                          : "0,00 €"}
                      </PorscheFont>
                    </td>
                    <td className="table-td"></td>
                    <td className="table-td">
                      <PorscheFont>
                        {recalculationOrderData &&
                        recalculationOrderData?.vehiclePrices
                          ? `${formatCurrencyWithNoUnit(
                              recalculationOrderData?.vehiclePrices.filter(
                                (itm) => itm.displayLabel === ele.displayLabel
                              )[0].amountNet - ele.amountNet
                            )} €`
                          : "0,00 €"}
                      </PorscheFont>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* Vehicle Price end */}

            {/* discountNetPrices start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top-16"
                >
                  <PorscheFont>{t("Discount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {orderData?.discountNetPrices?.map((ele, index) => {
                return (
                  <tr key={index}>
                    <td colSpan={2} className="table-td-left">
                      <PorscheFont>{ele.displayLabel}</PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.discountInEuro)} €`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.discountInPercent)} %`}
                      </PorscheFont>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="table-td">
                      <PorscheFont>
                        {recalDiscountNet &&
                        recalDiscountNet[index]?.discountInEuro
                          ? `${formatCurrencyWithNoUnit(
                              recalDiscountNet[index]?.discountInEuro!
                            )} €`
                          : "0,00 €"}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {recalDiscountNet &&
                        recalDiscountNet[index]?.discountInPercent
                          ? `${formatCurrencyWithNoUnit(
                              recalDiscountNet[index]?.discountInPercent!
                            )} %`
                          : "0,00 %"}
                      </PorscheFont>
                    </td>
                    <td></td>
                    <td
                      className={`table-${
                        recalDiscountNet &&
                        recalDiscountNet[index]?.discountInPercent &&
                        recalDiscountNet[index]?.discountInPercent! -
                          ele.discountInPercent >
                          0
                          ? "col-bold text-right"
                          : "td"
                      }`}
                    >
                      <PorscheFont>
                        {recalDiscountNet &&
                        recalDiscountNet[index]?.discountInPercent! -
                          ele.discountInPercent
                          ? `${formatCurrencyWithNoUnit(
                              recalDiscountNet[index]?.discountInPercent! -
                                ele.discountInPercent
                            )} %`
                          : "0,00 %"}
                      </PorscheFont>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Comment (Optional)")}</PorscheFont>
                </td>
                <td colSpan={8} className="table-td-left comment-col">
                  <PorscheFont>
                    {recalculationOrderData?.discountComment
                      ? recalculationOrderData?.discountComment
                      : orderData?.discountComment}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>
            {/* discountNetPrices End */}

            {/* kickbackNetPrices start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top-16"
                >
                  <PorscheFont>{t("Kickback (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Kickback in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Kickback in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Kickback in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Kickback in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {orderData?.kickbackNetPrices?.map((ele, index) => {
                return (
                  <tr key={index}>
                    <td colSpan={2} className="table-td-left">
                      <PorscheFont>{ele.displayLabel}</PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.kickbackInEuro)} €`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.kickbackInPercent)} %`}
                      </PorscheFont>
                    </td>
                    <td colSpan={2} />
                    <td className="table-td">
                      <PorscheFont>
                        {recalKickbat && recalKickbat[index]?.kickbackInEuro
                          ? `${formatCurrencyWithNoUnit(
                              recalKickbat[index]?.kickbackInEuro!
                            )} €`
                          : "0,00 €"}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {recalKickbat && recalKickbat[index]?.kickbackInPercent
                          ? `${formatCurrencyWithNoUnit(
                              recalKickbat[index]?.kickbackInPercent!
                            )} %`
                          : "0,00 %"}
                      </PorscheFont>
                    </td>
                    <td />
                    <td
                      className={`table-${
                        recalKickbat &&
                        recalKickbat[index]?.kickbackInPercent &&
                        recalKickbat[index]?.kickbackInPercent! -
                          ele.kickbackInPercent >
                          0
                          ? "col-bold text-right"
                          : "td"
                      }`}
                    >
                      <PorscheFont>
                        {recalKickbat &&
                        recalKickbat[index]?.kickbackInPercent! -
                          ele.kickbackInPercent
                          ? `${formatCurrencyWithNoUnit(
                              recalKickbat[index]?.kickbackInPercent! -
                                ele.kickbackInPercent
                            )} %`
                          : "0,00 %"}
                      </PorscheFont>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Description (C@P Ticket ID)")}</PorscheFont>
                </td>
                <td colSpan={8} className="table-td-left comment-col">
                  <PorscheFont>
                    {recalculationOrderData?.copTicketId
                      ? recalculationOrderData?.copTicketId
                      : orderData?.copTicketId}
                  </PorscheFont>
                </td>
              </tr>

              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>
                    {t("Description (Sales promotion)")}
                  </PorscheFont>
                </td>
                <td colSpan={8} className="table-td-left comment-col">
                  <PorscheFont>
                    {recalculationOrderData?.kickbackDescription
                      ? recalculationOrderData?.kickbackDescription
                      : orderData?.kickbackDescription}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>
            {/* kickbackNetPrices end */}

            {/* additionalCostsPrices start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top-16"
                >
                  <PorscheFont>{t("Additional costs")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {orderData?.additionalCostsPrices?.map((ele, index) => {
                // if (ele.label.length > 0)
                return (
                  <React.Fragment>
                    <tr key={index}>
                      <td colSpan={2} className="table-td-left">
                        <PorscheFont style={{ width: "210px" }}>
                          {ele.label !== "" ? ele.label : ele.type}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.discountInEuro)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            ele.discountInPercent
                          )} %`}
                        </PorscheFont>
                      </td>
                      <td />
                      <td className="table-td">
                        <PorscheFont>
                          {recalAdditionalCost &&
                          recalAdditionalCost[index]?.amountNet
                            ? `${formatCurrencyWithNoUnit(
                                recalAdditionalCost[index]?.amountNet!
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalAdditionalCost &&
                          recalAdditionalCost[index]?.discountInEuro
                            ? `${formatCurrencyWithNoUnit(
                                recalAdditionalCost[index]?.discountInEuro!
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalAdditionalCost &&
                          recalAdditionalCost[index]?.discountInPercent
                            ? `${formatCurrencyWithNoUnit(
                                recalAdditionalCost[index]?.discountInPercent!
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>

                      <td
                        className={`table-${
                          recalAdditionalCost &&
                          recalAdditionalCost[index]?.discountInPercent &&
                          recalAdditionalCost[index]?.discountInPercent! -
                            ele.discountInPercent >
                            0
                            ? "col-bold text-right"
                            : "td"
                        }`}
                      >
                        <PorscheFont>
                          {recalAdditionalCost &&
                          recalAdditionalCost[index]?.discountInPercent! -
                            ele.discountInPercent
                            ? `${formatCurrencyWithNoUnit(
                                recalAdditionalCost[index]?.discountInPercent! -
                                  ele.discountInPercent
                              )} %`
                            : "0,00 %"}
                        </PorscheFont>
                      </td>
                    </tr>
                    {/* {orderData?.additionalCostPriceComment !== "" && ( */}
                  </React.Fragment>
                );
              })}
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Description (Other)")}</PorscheFont>
                </td>
                <td colSpan={8} className="table-td-left comment-col">
                  <PorscheFont>
                    {recalculationOrderData?.additionalCostPriceComment
                      ? recalculationOrderData?.additionalCostPriceComment
                      : orderData?.additionalCostPriceComment}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>
            {/* additionalCostsPrices end */}

            {/* Workshop costs start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top-16"
                >
                  <PorscheFont>{t("Workshop costs")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {/* {} */}
              {orderData?.workshopCosts?.upsaleItems?.map((ele, index) => {
                if (ele.partType === "tire") {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td colSpan={2} className="table-td-left">
                          <PorscheFont>
                            {t("Complete Winter Tires")}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(
                              getAmountNet(ele, false, 0)
                            )} €`}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {formatCurrencyWithNoUnit(
                              getAmountNet(ele, false, 0) - ele.customerShare
                            )}
                            {" €"}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {orderData.vehiclePrices
                              ? formatCurrencyWithNoUnit(
                                  ((getAmountNet(ele, false, 0) -
                                    ele.customerShare) /
                                    orderData.vehiclePrices[2].amountNet) *
                                    100
                                )
                              : "0.00"}
                            {"%"}
                          </PorscheFont>
                        </td>
                        <td />
                        {recalculationOrderData?.workshopCosts?.upsaleItems && (
                          <>
                            {" "}
                            <td className="table-td">
                              <PorscheFont>
                                {`${formatCurrencyWithNoUnit(
                                  getAmountNet(
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index],
                                    false,
                                    0
                                  )
                                )} €`}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {`${formatCurrencyWithNoUnit(
                                  getAmountNet(
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index],
                                    false,
                                    0
                                  ) -
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index].customerShare
                                )} €`}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {recalVehiclePrices &&
                                recalVehiclePrices[2]?.amountNet &&
                                orderData.vehiclePrices
                                  ? formatCurrencyWithNoUnit(
                                      ((getAmountNet(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        false,
                                        0
                                      ) -
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index].customerShare) /
                                        recalVehiclePrices[2].amountNet) *
                                        100
                                    )
                                  : "0.00"}
                                {"%"}
                              </PorscheFont>
                            </td>
                          </>
                        )}
                        <td className="table-td">
                          <PorscheFont>
                            {recalculationOrderData?.workshopCosts
                              ?.upsaleItems &&
                              formatCurrencyWithNoUnit(
                                getPercent(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0,
                                  "re"
                                ) - getPercent(ele, false, 0, "pre")
                              )}
                            {" %"}
                          </PorscheFont>
                        </td>
                      </tr>
                      {ele.serviceItems.map((service, j) => {
                        if (service.services !== null) {
                          return (
                            <tr>
                              <td colSpan={2} className="table-td-left">
                                <PorscheFont>
                                  {getWorkshopServiceName(
                                    ele.serviceItems[j].services
                                      .serviceNameUpsale,
                                    j === 0 ? "winter" : "summer"
                                  )}
                                </PorscheFont>
                              </td>
                              <td className="table-td">
                                <PorscheFont>
                                  {`${formatCurrencyWithNoUnit(
                                    printGetAmountNet(ele, true, j)
                                  )} €`}
                                </PorscheFont>
                              </td>
                              <td className="table-td">
                                <PorscheFont>
                                  {/* {`${formatCurrencyWithNoUnit(ele.serviceItems[j].customerShare)} €`} */}
                                  {formatCurrencyWithNoUnit(
                                    printGetAmountNet(ele, true, j) > 0
                                      ? printGetAmountNet(ele, true, j) -
                                          service.customerShare
                                      : 0.0
                                  )}
                                  {" €"}
                                </PorscheFont>
                              </td>
                              <td className="table-td">
                                <PorscheFont>
                                  {orderData.vehiclePrices
                                    ? formatCurrencyWithNoUnit(
                                        ((getAmountNet(ele, true, j) -
                                          service.customerShare) /
                                          orderData.vehiclePrices[2]
                                            .amountNet) *
                                          100
                                      )
                                    : "0.00"}
                                  {"%"}
                                </PorscheFont>
                              </td>
                              <td />
                              <td className="table-td">
                                <PorscheFont>
                                  {`${
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index] &&
                                    formatCurrencyWithNoUnit(
                                      getAmountNet(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        j
                                      )
                                    )
                                  } €`}
                                </PorscheFont>
                              </td>
                              <td className="table-td">
                                <PorscheFont>
                                  {`${
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index] &&
                                    formatCurrencyWithNoUnit(
                                      getAmountNet(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        j
                                      ) > 0
                                        ? getAmountNet(
                                            recalculationOrderData
                                              ?.workshopCosts?.upsaleItems[
                                              index
                                            ],
                                            true,
                                            j
                                          ) - service.customerShare
                                        : 0.0
                                    )
                                  } €`}
                                </PorscheFont>
                              </td>
                              <td className="table-td">
                                <PorscheFont>
                                  {recalVehiclePrices &&
                                  recalVehiclePrices[2]?.amountNet &&
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index] &&
                                  orderData.vehiclePrices
                                    ? formatCurrencyWithNoUnit(
                                        ((getAmountNet(
                                          recalculationOrderData?.workshopCosts
                                            ?.upsaleItems[index],
                                          true,
                                          j
                                        ) -
                                          recalculationOrderData?.workshopCosts
                                            ?.upsaleItems[index]
                                            .customerShare) /
                                          recalVehiclePrices[2].amountNet) *
                                          100
                                      )
                                    : "0.00"}
                                  {"%"}
                                </PorscheFont>
                              </td>
                              <td className="table-td">
                                <PorscheFont>
                                  {recalculationOrderData?.workshopCosts
                                    ?.upsaleItems &&
                                    formatCurrencyWithNoUnit(
                                      getPercent(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        j,
                                        "re"
                                      ) - getPercent(ele, true, j, "pre")
                                    )}
                                  {" %"}
                                </PorscheFont>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </React.Fragment>
                  );
                }

                if (ele.partType === "part") {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td colSpan={2} className="table-td-left">
                          <PorscheFont>{t("PDS / Tequipment")}</PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(
                              getAmountNet(ele, false, 0)
                            )} €`}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {formatCurrencyWithNoUnit(ele.discount? ele.discount:0)}
                            {" €"}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {/* {orderData && orderData.vehiclePrices && (getAmountNet(ele, false, 0)- ele.customerShare)/ orderData?.vehiclePrices[2].amountNet * 100} */}

        {/* {formatCurrencyWithNoUnit(calculateDiscountPercentage(ele))} %  ----------------- */}

                            {`${
                              orderData &&
                              orderData.vehiclePrices &&
                              formatCurrencyWithNoUnit(
                                ((getAmountNet(ele, false, 0) -
                                  ele.customerShare) /
                                  orderData?.vehiclePrices[2].amountNet) *
                                  100
                              )
                            } %`}
                          </PorscheFont>
                        </td>
                        <td />
                        <td className="table-td">
                          <PorscheFont>
                            {`${
                              recalculationOrderData &&
                              recalculationOrderData?.workshopCosts
                                ?.upsaleItems &&
                              formatCurrencyWithNoUnit(
                                getAmountNet(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0
                                )
                              )
                            } €`}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {recalculationOrderData?.workshopCosts?.upsaleItems[
                              index
                            ] &&
                              formatCurrencyWithNoUnit(
                                getAmountNet(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0
                                ) -
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index].customerShare
                              )}
                            {" €"}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {recalVehiclePrices &&
                            recalculationOrderData?.workshopCosts
                              ?.upsaleItems &&
                            recalculationOrderData?.workshopCosts?.upsaleItems[
                              index
                            ] &&
                            recalVehiclePrices[2]?.amountNet &&
                            orderData.vehiclePrices
                              ? formatCurrencyWithNoUnit(
                                  ((printGetAmountNet(
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index],
                                    false,
                                    0
                                  ) -
                                    recalculationOrderData?.workshopCosts
                                      ?.upsaleItems[index].customerShare) /
                                    recalVehiclePrices[2].amountNet) *
                                    100
                                )
                              : "0.00"}
                            {"%"}
                          </PorscheFont>
                        </td>
                        <td className="table-td">
                          <PorscheFont>
                            {recalculationOrderData?.workshopCosts
                              ?.upsaleItems &&
                              formatCurrencyWithNoUnit(
                                getPercent(
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems[index],
                                  false,
                                  0,
                                  "re"
                                ) - getPercent(ele, false, 0, "pre")
                              )}
                            {" %"}
                          </PorscheFont>
                        </td>
                      </tr>
                      {ele.serviceItems.map((service, j) => {
                        if (!(service.services.priceBeforeCustomerNet > 0))
                          return <></>;
                        return (
                          <tr>
                            <td colSpan={2} className="table-td-left">
                              <PorscheFont>
                                {t("Installation  (PDS/TEQ)")}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {`${formatCurrencyWithNoUnit(
                                  getAmountNet(ele, true, j) / 100
                                )} €`}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {formatCurrencyWithNoUnit(
                                  printGetAmountNet(ele, true, j) > 0
                                    ? printGetAmountNet(ele, true, j) -
                                        service.customerShare
                                    : 0.0
                                )}
                                {" €"}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {orderData &&
                                orderData.vehiclePrices &&
                                orderData.vehiclePrices.length > 0
                                  ? formatCurrencyWithNoUnit(
                                      ((printGetAmountNet(ele, true, j) -
                                        service.customerShare) /
                                        orderData.vehiclePrices[2].amountNet) *
                                        100
                                    )
                                  : "0,00"}
                                {" %"}
                              </PorscheFont>
                            </td>
                            <td />
                            <td className="table-td">
                              <PorscheFont>
                                {`${
                                  recalculationOrderData?.workshopCosts
                                    ?.upsaleItems &&
                                  formatCurrencyWithNoUnit(
                                    getAmountNet(
                                      recalculationOrderData?.workshopCosts
                                        ?.upsaleItems[index],
                                      false,
                                      0
                                    )
                                  )
                                } €`}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {recalculationOrderData?.workshopCosts
                                  ?.upsaleItems &&
                                  formatCurrencyWithNoUnit(
                                    printGetAmountNet(
                                      recalculationOrderData?.workshopCosts
                                        ?.upsaleItems[index],
                                      true,
                                      j
                                    ) > 0
                                      ? printGetAmountNet(
                                          recalculationOrderData?.workshopCosts
                                            ?.upsaleItems[index],
                                          true,
                                          j
                                        ) - service.customerShare
                                      : 0.0
                                  )}
                                {" €"}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {/* {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0) - ele.customerShare)} %`} */}
                                {recalVehiclePrices &&
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems &&
                                recalculationOrderData?.workshopCosts
                                  ?.upsaleItems[index] &&
                                recalVehiclePrices[2]?.amountNet &&
                                orderData.vehiclePrices
                                  ? formatCurrencyWithNoUnit(
                                      ((printGetAmountNet(
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index],
                                        true,
                                        0
                                      ) -
                                        recalculationOrderData?.workshopCosts
                                          ?.upsaleItems[index].customerShare) /
                                        recalVehiclePrices[2].amountNet) *
                                        100
                                    )
                                  : "0.00"}
                              </PorscheFont>
                            </td>
                            <td className="table-td">
                              <PorscheFont>
                                {recalculationOrderData?.workshopCosts
                                  ?.upsaleItems &&
                                  formatCurrencyWithNoUnit(
                                    getPercent(
                                      recalculationOrderData?.workshopCosts
                                        ?.upsaleItems[index],
                                      true,
                                      j,
                                      "re"
                                    ) - getPercent(ele, true, j, "pre")
                                  )}
                                {" %"}
                              </PorscheFont>
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                }

                // sample
              })}
              {/* Workshop costs end */}

              {/** Single part start */}
              {/* {parts && parts.length === 1 && orderData?.workshopCosts?.upsaleItems?.map((ele, index) => {
                if (ele.partType === "part") {
                  return (<>
                    <tr key={index}>
                      <td colSpan={2} className='table-td-left'>
                        <PorscheFont>
                          {t("PDS / Tequipment")}
                        </PorscheFont>
                      </td>
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0))} €`}
                        </PorscheFont>
                      </td>
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.customerShare)} €`}
                        </PorscheFont>
                      </td>
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0) - ele.customerShare)} %`}
                        </PorscheFont>
                      </td>
                      <td />
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.customerShare)} €`}
                        </PorscheFont>
                      </td>
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0) - ele.customerShare)} €`}
                        </PorscheFont>
                      </td>
                      <td className='table-td'>
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(getPercent(ele, false, 0, "NewCar", orderData))} %`}
                        </PorscheFont>
                      </td>
                      {(index % 2) ?
                        (<td className='table-col-bold text-right'>
                          <PorscheFont>
                            +1,00%
                          </PorscheFont>
                        </td>)
                        :
                        (<td className='table-td'>
                          <PorscheFont>
                            0,00%
                          </PorscheFont>
                        </td>)}
                    </tr>

                    {ele.serviceItems.map((service,j)=>{
                      if(
                        service.services.priceBeforeCustomerNet < 0

                        // !(service.services.priceBeforeCustomerNet > 0)
                        )return(<></>)
                      return(
                        <tr key={index}>
                        <td colSpan={2} className='table-td-left'>
                          <PorscheFont>
                            {t("PDsdfsdfsfdt")}
                          </PorscheFont>
                        </td>
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0))} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(ele.customerShare)} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0) - ele.customerShare)} %`}
                          </PorscheFont>
                        </td>
                        <td />
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(ele.customerShare)} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(getAmountNet(ele, false, 0) - ele.customerShare)} €`}
                          </PorscheFont>
                        </td>
                        <td className='table-td'>
                          <PorscheFont>
                            {`${formatCurrencyWithNoUnit(getPercent(ele, false, 0, "NewCar", orderData))} %`}
                          </PorscheFont>
                        </td>
                        {(index % 2) ?
                          (<td className='table-col-bold text-right'>
                            <PorscheFont>
                              +1,00%
                            </PorscheFont>
                          </td>)
                          :
                          (<td className='table-td'>
                            <PorscheFont>
                              0,00%
                            </PorscheFont>
                          </td>)}
                      </tr>
                      )
                    })}
                    </>
                  )
                }
              })} */}
              {/** Single part  end */}
              {/** Multiple parts */}
              {/* { parts && parts.length > 1 && (

                  )
              } */}
            </tbody>
            {/* Workshop costs end */}

            {/* Trade-in (regular taxed) start  */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top-16"
                >
                  <PorscheFont>{t("Trade-in (regular taxed)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td"></td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Discount in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top-16 table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {orderData?.tradeInPrices?.map((ele, index) => {
                if (index > 2) {
                  let show = false;
                  if (
                    order.tradeInDetails &&
                    (order.tradeInDetails.tradeInType ===
                      "Leasing_Redemption" ||
                      order.tradeInDetails.tradeInType ===
                        "Financing_Redemption_Regular" ||
                      order.tradeInDetails.tradeInType ===
                        "Financing_Redemption_Differential") &&
                    ((orderData &&
                      orderData?.tradeInPrices &&
                      orderData?.tradeInPrices[2]?.amountNet < 0) ||
                      (recalTrandInPrices! &&
                        recalTrandInPrices[2]?.amountNet! < 0))
                  ) {
                    show = true;
                  }

                  if (!show) return <></>;
                }
                if (index === 2) {
                  return (
                    <tr key={index}>
                      <td colSpan={2} className="table-td-left">
                        <PorscheFont>
                          {orderData?.tradeInPrices! &&
                          orderData?.tradeInPrices[2]?.amountNet >= 0 &&
                          recalTrandInPrices! &&
                          recalTrandInPrices[2]?.amountNet! >= 0
                            ? t("Profit booking")
                            : orderData?.tradeInPrices! &&
                              orderData?.tradeInPrices[2]?.amountNet < 0 &&
                              recalTrandInPrices! &&
                              recalTrandInPrices[2]?.amountNet! < 0
                            ? t("Loss booking")
                            : t("Booking")}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(ele.amountGross)} €`}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        {order?.tradeInDetails &&
                          order?.tradeInDetails?.tradeInType !==
                            "Leasing_Redemption" &&
                          order.tradeInDetails.tradeInType !==
                            "Financing_Redemption_Regular" &&
                          order.tradeInDetails.tradeInType !==
                            "Financing_Redemption_Differential" && (
                            <>
                              <PorscheFont>
                                {orderData?.vehiclePrices &&
                                orderData?.vehiclePrices[2]?.amountNet > 0
                                  ? formatCurrencyWithNoUnit(
                                      orderData?.tradeInPrices! &&
                                        (orderData?.tradeInPrices[2]
                                          ?.amountNet /
                                          orderData?.vehiclePrices[2]
                                            ?.amountNet) *
                                          -1 *
                                          100
                                    )
                                  : "0.00"}
                                {" %"}
                              </PorscheFont>
                            </>
                          )}

                        {order.tradeInDetails &&
                          (order.tradeInDetails.tradeInType ===
                            "Leasing_Redemption" ||
                            order.tradeInDetails.tradeInType ===
                              "Financing_Redemption_Regular" ||
                            order.tradeInDetails.tradeInType ===
                              "Financing_Redemption_Differential") &&
                          orderData?.tradeInPrices! &&
                          orderData?.tradeInPrices[2]?.amountNet >= 0 && (
                            <PorscheFont>
                              {orderData?.vehiclePrices &&
                              orderData?.vehiclePrices[2]?.amountNet > 0
                                ? formatCurrencyWithNoUnit(
                                    orderData?.tradeInPrices! &&
                                      (orderData?.tradeInPrices[2]?.amountNet /
                                        orderData?.vehiclePrices[2]
                                          ?.amountNet) *
                                        -1 *
                                        100
                                  )
                                : "0.00"}
                              {" %"}
                            </PorscheFont>
                          )}
                      </td>
                      <td />
                      <td className="table-td">
                        <PorscheFont>
                          {recalTrandInPrices
                            ? `${formatCurrencyWithNoUnit(
                                recalTrandInPrices[index]?.amountNet!
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        <PorscheFont>
                          {recalTrandInPrices
                            ? `${formatCurrencyWithNoUnit(
                                recalTrandInPrices[index]?.amountGross!
                              )} €`
                            : "0,00 €"}
                        </PorscheFont>
                      </td>
                      <td className="table-td">
                        {order?.tradeInDetails &&
                          order?.tradeInDetails?.tradeInType !==
                            "Leasing_Redemption" &&
                          order.tradeInDetails.tradeInType !==
                            "Financing_Redemption_Regular" &&
                          order.tradeInDetails.tradeInType !==
                            "Financing_Redemption_Differential" && (
                            <>
                              <PorscheFont>
                                {recalTrandInPrices! &&
                                recalVehiclePrices! &&
                                recalVehiclePrices[2]?.amountNet! > 0
                                  ? formatCurrencyWithNoUnit(
                                      recalVehiclePrices[2]?.amountNet! > 0
                                        ? (recalTrandInPrices[2]?.amountNet! /
                                            recalVehiclePrices[2]?.amountNet!) *
                                            -1 *
                                            100
                                        : 0.0
                                    )
                                  : "0.00"}
                                {" %"}
                              </PorscheFont>
                            </>
                          )}

                        {order.tradeInDetails &&
                          (order.tradeInDetails.tradeInType ===
                            "Leasing_Redemption" ||
                            order.tradeInDetails.tradeInType ===
                              "Financing_Redemption_Regular" ||
                            order.tradeInDetails.tradeInType ===
                              "Financing_Redemption_Differential") &&
                          recalTrandInPrices! &&
                          recalTrandInPrices[2]?.amountNet! >= 0 && (
                            <PorscheFont>
                              {recalVehiclePrices &&
                              recalVehiclePrices[2]?.amountNet! > 0
                                ? formatCurrencyWithNoUnit(
                                    recalVehiclePrices[2]?.amountNet! > 0
                                      ? (recalTrandInPrices[2]?.amountNet! /
                                          recalVehiclePrices[2]?.amountNet!) *
                                          -1 *
                                          100
                                      : 0.0
                                  )
                                : "0.00"}
                              {" %"}
                            </PorscheFont>
                          )}
                      </td>
                      <td
                        className={checkBoldTextForDeviation(
                          ele.displayLabel === "Share PC"
                            ? getSharePcPercent()
                            : orderData &&
                              orderData?.tradeInPrices &&
                              recalTrandInPrices
                            ? recalTrandInPrices[index]?.amountNet! -
                              orderData?.tradeInPrices[index]?.amountNet
                            : 0
                        )}
                      >
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(
                            ele.displayLabel === "Share PC"
                              ? getSharePcPercent()
                              : orderData &&
                                orderData?.tradeInPrices &&
                                recalTrandInPrices
                              ? recalTrandInPrices[index]?.amountNet! -
                                orderData?.tradeInPrices[index]?.amountNet
                              : 0
                          )}
                          {ele.displayLabel === "Share PC" ? " %" : " €"}
                        </PorscheFont>
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr key={index}>
                    <td colSpan={2} className="table-td-left">
                      <PorscheFont>{ele.displayLabel}</PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.amountNet)} €`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.amountGross)} €`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      {calculateValueOfTradeInDiscount(order, "precal") !==
                      null ? (
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(
                            calculateValueOfTradeInDiscount(order, "precal") ||
                              0
                          )}
                          {" %"}
                        </PorscheFont>
                      ) : null}
                    </td>
                    <td />
                    <td className="table-td">
                      <PorscheFont>
                        {recalTrandInPrices
                          ? `${formatCurrencyWithNoUnit(
                              recalTrandInPrices[index]?.amountNet!
                            )} €`
                          : "0,00 €"}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {recalculationOrderData && recalTrandInPrices
                          ? `${formatCurrencyWithNoUnit(
                              recalTrandInPrices[index]?.amountGross!
                            )} €`
                          : "0,00 €"}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      {index === 3 &&
                      calculateValueOfTradeInDiscount(order, "recal") !==
                        null ? (
                        <PorscheFont>
                          {formatCurrencyWithNoUnit(
                            calculateValueOfTradeInDiscount(order, "recal") || 0
                          )}
                          {" %"}
                        </PorscheFont>
                      ) : null}
                    </td>
                    <td
                      className={checkBoldTextForDeviation(
                        ele.displayLabel === "Share PC"
                          ? getSharePcPercent()
                          : orderData &&
                            orderData?.tradeInPrices &&
                            recalculationOrderData &&
                            recalTrandInPrices
                          ? recalTrandInPrices[index]?.amountNet! -
                            orderData?.tradeInPrices[index]?.amountNet
                          : 0
                      )}
                    >
                      <PorscheFont>
                        {formatCurrencyWithNoUnit(
                          ele.displayLabel === "Share PC"
                            ? getSharePcPercent()
                            : orderData &&
                              orderData?.tradeInPrices &&
                              recalculationOrderData &&
                              recalTrandInPrices
                            ? recalTrandInPrices[index]?.amountNet! -
                              orderData?.tradeInPrices[index]?.amountNet
                            : 0
                        )}
                        {ele.displayLabel === "Share PC" ? " %" : " €"}
                      </PorscheFont>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className="table-td-left">
                  <PorscheFont>{t("Comment (Optional)")}</PorscheFont>
                </td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>{orderData?.tradeInComment}</PorscheFont>
                </td>
                <td colSpan={3}></td>
                <td className="table-td">
                  <PorscheFont>
                    {recalculationOrderData?.tradeInComment}
                  </PorscheFont>
                </td>
                <td colSpan={2}></td>
                <td className="table-td"></td>
              </tr>
            </tbody>
            {/* Trade-in (regular taxed) end  */}

            {/* Leasing In Start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top"
                >
                  <PorscheFont>{t("Leasing")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {orderData?.calculationPaymentDetails?.paymentType !== "CASH" && (
                <tr>
                  <td colSpan={2} className="table-td-left">
                    <PorscheFont>
                      {getPayementTypeName(
                        orderData?.calculationPaymentDetails?.paymentType
                          ? orderData?.calculationPaymentDetails?.paymentType
                          : ""
                      ) === "Leasing"
                        ? t("Special leasing payment")
                        : t("Down payment")}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        orderData?.calculationPaymentDetails?.paymentNet!
                      )} €`}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        orderData?.calculationPaymentDetails?.paymentGross!
                      )} €`}
                    </PorscheFont>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        recalculationOrderData?.calculationPaymentDetails
                          ?.paymentNet!
                      )} €`}
                    </PorscheFont>
                  </td>
                  <td className="table-td">
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        recalculationOrderData?.calculationPaymentDetails
                          ?.paymentGross!
                      )} €`}
                    </PorscheFont>
                  </td>
                  <td></td>

                  <td
                    className={`table-${
                      orderData?.calculationPaymentDetails?.paymentNet &&
                      recalculationOrderData?.calculationPaymentDetails
                        ?.paymentNet &&
                      recalculationOrderData?.calculationPaymentDetails
                        ?.paymentNet! -
                        orderData?.calculationPaymentDetails?.paymentNet >
                        0
                        ? "col-bold text-right"
                        : "td"
                    }`}
                  >
                    <PorscheFont>
                      {orderData?.calculationPaymentDetails?.paymentNet &&
                      recalculationOrderData?.calculationPaymentDetails
                        ?.paymentNet! -
                        orderData?.calculationPaymentDetails?.paymentNet
                        ? `${formatCurrencyWithNoUnit(
                            recalculationOrderData?.calculationPaymentDetails
                              ?.paymentNet! -
                              orderData?.calculationPaymentDetails?.paymentNet
                          )} €`
                        : "0,00 €"}
                    </PorscheFont>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Up-front payment")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationPaymentDetails?.upFrontPaymentNet
                        ? orderData?.calculationPaymentDetails
                            ?.upFrontPaymentNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationPaymentDetails?.upFrontPaymentGross
                        ? orderData?.calculationPaymentDetails
                            ?.upFrontPaymentGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationPaymentDetails
                        ?.upFrontPaymentNet
                        ? recalculationOrderData?.calculationPaymentDetails
                            ?.upFrontPaymentNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationPaymentDetails
                        ?.upFrontPaymentGross
                        ? recalculationOrderData?.calculationPaymentDetails
                            ?.upFrontPaymentGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.calculationPaymentDetails?.upFrontPaymentNet &&
                    recalculationOrderData?.calculationPaymentDetails
                      ?.upFrontPaymentNet &&
                    recalculationOrderData?.calculationPaymentDetails
                      ?.upFrontPaymentNet! -
                      orderData?.calculationPaymentDetails?.upFrontPaymentNet >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {orderData?.calculationPaymentDetails?.upFrontPaymentNet &&
                    recalculationOrderData?.calculationPaymentDetails
                      ?.upFrontPaymentNet! -
                      orderData?.calculationPaymentDetails?.upFrontPaymentNet
                      ? `${formatCurrencyWithNoUnit(
                          recalculationOrderData?.calculationPaymentDetails
                            ?.upFrontPaymentNet! -
                            orderData?.calculationPaymentDetails
                              ?.upFrontPaymentNet
                        )} €`
                      : "0,00 €"}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>
            {/* Lessing In End */}

            {/* Final Calculation start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top"
                >
                  <PorscheFont>{t("Final Calculation")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("% of RRP (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("% of RRP (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-col-bold text-left">
                  <PorscheFont>{t("External discount")}</PorscheFont>
                </td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationHeader?.externalDiscount
                        ? orderData?.calculationHeader?.externalDiscount
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${
                      orderData?.calculationHeader !== null &&
                      orderData?.calculationHeader?.externalDiscount &&
                      orderData?.vehiclePrices &&
                      orderData?.vehiclePrices[2].amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (orderData?.calculationHeader?.externalDiscount /
                              orderData?.vehiclePrices[2].amountNet) *
                              100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationHeader
                        ?.externalDiscount
                        ? recalculationOrderData?.calculationHeader
                            ?.externalDiscount
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${
                      recalculationOrderData?.calculationHeader !== null &&
                      recalculationOrderData?.calculationHeader
                        ?.externalDiscount &&
                      recalculationOrderData?.vehiclePrices &&
                      recalculationOrderData?.vehiclePrices[2].amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (recalculationOrderData?.calculationHeader
                              ?.externalDiscount /
                              recalculationOrderData?.vehiclePrices[2]
                                .amountNet) *
                              100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.calculationHeader?.externalDiscount &&
                    recalculationOrderData?.calculationHeader
                      ?.externalDiscount &&
                    recalculationOrderData?.calculationHeader
                      ?.externalDiscount! -
                      orderData?.calculationHeader?.externalDiscount >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.calculationHeader
                        ?.externalDiscount ?? 0) -
                        (orderData?.calculationHeader?.externalDiscount ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-col-bold text-left">
                  <PorscheFont>{t("Internal discount")}</PorscheFont>
                </td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationHeader?.internalDiscount
                        ? orderData?.calculationHeader?.internalDiscount
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${
                      orderData?.calculationHeader?.internalDiscount &&
                      orderData?.vehiclePrices &&
                      orderData?.vehiclePrices[2].amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (orderData?.calculationHeader?.internalDiscount /
                              orderData?.vehiclePrices[2].amountNet) *
                              100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationHeader
                        ?.internalDiscount
                        ? recalculationOrderData?.calculationHeader
                            ?.internalDiscount
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${
                      recalculationOrderData?.calculationHeader
                        ?.internalDiscount &&
                      recalculationOrderData?.vehiclePrices &&
                      recalculationOrderData?.vehiclePrices[2].amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (recalculationOrderData?.calculationHeader
                              ?.internalDiscount /
                              recalculationOrderData?.vehiclePrices[2]
                                .amountNet) *
                              100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.calculationHeader?.internalDiscount &&
                    recalculationOrderData?.calculationHeader
                      ?.internalDiscount &&
                    recalculationOrderData?.calculationHeader
                      ?.internalDiscount! -
                      orderData?.calculationHeader?.internalDiscount >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.calculationHeader
                        ?.internalDiscount ?? 0) -
                        (orderData?.calculationHeader?.internalDiscount ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              {(order.paymentDetails?.paymentType === "Leasing_PFS" ||
                order.paymentDetails?.paymentType === "Leasing_Other") &&
                rvm &&
                rvm.rrp > 0 && (
                  <tr>
                    <td colSpan={2} className="table-td-left">
                      <PorscheFont>{t("Residual value model")}</PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {` ${formatCurrencyWithNoUnit(
                          orderData?.vehiclePrices
                            ? rvm.rrp * orderData?.vehiclePrices[2].amountNet
                            : 0.0
                        )} €  `}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${
                          rvm.rrp ? formatCurrencyWithNoUnit(rvm.rrp) : "0,00"
                        } %`}
                      </PorscheFont>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="table-td">
                      <PorscheFont>
                        {` ${formatCurrencyWithNoUnit(
                          recalculationOrderData?.vehiclePrices
                            ? rvm.rrp *
                                recalculationOrderData?.vehiclePrices[2]
                                  .amountNet
                            : 0.0
                        )} €  `}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${
                          rvm.rrp ? formatCurrencyWithNoUnit(rvm.rrp) : "0,00"
                        } %`}
                      </PorscheFont>
                    </td>
                    <td></td>
                    {false ? (
                      <td className="table-col-bold text-right">
                        <PorscheFont>+2.000,00 €</PorscheFont>
                      </td>
                    ) : (
                      <td className="table-td">
                        <PorscheFont>0,00 €</PorscheFont>
                      </td>
                    )}
                  </tr>
                )}
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Gross profit")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationHeader?.grossProfit
                        ? orderData?.calculationHeader?.grossProfit
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${
                      orderData?.calculationHeader?.grossProfit &&
                      orderData?.vehiclePrices &&
                      orderData?.vehiclePrices[2].amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (orderData?.calculationHeader?.grossProfit /
                              orderData?.vehiclePrices[2].amountNet) *
                              100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationHeader?.grossProfit
                        ? recalculationOrderData?.calculationHeader?.grossProfit
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${
                      recalculationOrderData?.calculationHeader?.grossProfit &&
                      recalculationOrderData?.vehiclePrices &&
                      recalculationOrderData?.vehiclePrices[2].amountNet > 0
                        ? formatCurrencyWithNoUnit(
                            (recalculationOrderData?.calculationHeader
                              ?.grossProfit /
                              recalculationOrderData?.vehiclePrices[2]
                                .amountNet) *
                              100
                          )
                        : "0,00"
                    } %`}
                  </PorscheFont>
                </td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.calculationHeader?.grossProfit &&
                    recalculationOrderData?.calculationHeader?.grossProfit &&
                    recalculationOrderData?.calculationHeader?.grossProfit! -
                      orderData?.calculationHeader?.grossProfit >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.calculationHeader?.grossProfit ??
                        0) - (orderData?.calculationHeader?.grossProfit ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>
            {/* Final Calculation end */}

            {/*  Calculation customer start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top"
                >
                  <PorscheFont>{t("Calculation customer")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (net)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount (gross)")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Total invoice amount")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.invoiceNet
                        ? orderData?.calculationCustomer?.invoiceNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.invoiceGross
                        ? orderData?.calculationCustomer?.invoiceGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer?.invoiceNet
                        ? recalculationOrderData?.calculationCustomer
                            ?.invoiceNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer?.invoiceGross
                        ? recalculationOrderData?.calculationCustomer
                            ?.invoiceGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.calculationCustomer?.invoiceNet &&
                    recalculationOrderData?.calculationCustomer?.invoiceNet &&
                    recalculationOrderData?.calculationCustomer?.invoiceNet! -
                      orderData?.calculationCustomer?.invoiceNet >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {orderData?.calculationCustomer?.invoiceNet
                      ? `${formatCurrencyWithNoUnit(
                          recalculationOrderData?.calculationCustomer
                            ?.invoiceNet! -
                            orderData?.calculationCustomer?.invoiceNet
                        )} €`
                      : "0,00 €"}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Pre-delivery payment")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.preDeliveryNet
                        ? orderData?.calculationCustomer?.preDeliveryNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.preDeliveryGross
                        ? orderData?.calculationCustomer?.preDeliveryGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer
                        ?.preDeliveryNet
                        ? recalculationOrderData?.calculationCustomer
                            ?.preDeliveryNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer
                        ?.preDeliveryGross
                        ? recalculationOrderData?.calculationCustomer
                            ?.preDeliveryGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.calculationCustomer?.paymentNet &&
                    recalculationOrderData?.calculationCustomer
                      ?.preDeliveryNet &&
                    recalculationOrderData?.calculationCustomer
                      ?.preDeliveryNet! -
                      orderData?.calculationCustomer?.preDeliveryNet >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.calculationCustomer
                        ?.preDeliveryNet ?? 0) -
                        (orderData?.calculationCustomer?.preDeliveryNet ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Leasing amount")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.paymentNet
                        ? orderData?.calculationCustomer?.paymentNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.calculationCustomer?.paymentGross
                        ? orderData?.calculationCustomer?.paymentGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer?.paymentNet
                        ? recalculationOrderData?.calculationCustomer
                            ?.paymentNet
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.calculationCustomer?.paymentGross
                        ? recalculationOrderData?.calculationCustomer
                            ?.paymentGross
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.calculationCustomer?.paymentNet &&
                    recalculationOrderData?.calculationCustomer?.paymentNet &&
                    recalculationOrderData?.calculationCustomer?.paymentNet! -
                      orderData?.calculationCustomer?.paymentNet >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.calculationCustomer
                        ?.paymentNet ?? 0) -
                        (orderData?.calculationCustomer?.paymentNet ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>
            {/*  Calculation customer end */}

            {/* Commission calculation  In Start */}
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  className="table-col-bold table-th-border-bottom p-top"
                >
                  <PorscheFont>{t("Commission calculation")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Amount in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td"></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Vehicle commission")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.commissionCalculation?.vehicleCommissionEuro
                        ? orderData?.commissionCalculation
                            ?.vehicleCommissionEuro
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.commissionCalculation
                        ?.vehicleCommissionEuro
                        ? recalculationOrderData?.commissionCalculation
                            ?.vehicleCommissionEuro
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.commissionCalculation?.vehicleCommissionEuro &&
                    recalculationOrderData?.commissionCalculation
                      ?.vehicleCommissionEuro &&
                    recalculationOrderData?.commissionCalculation
                      ?.vehicleCommissionEuro! -
                      orderData?.commissionCalculation?.vehicleCommissionEuro >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.commissionCalculation
                        ?.vehicleCommissionEuro ?? 0) -
                        (orderData?.commissionCalculation
                          ?.vehicleCommissionEuro ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={5} className="table-td-left">
                  <PorscheFont>
                    {t(`Standard: ${orderData?.commissionCalculation?.referenceValue} % of ${orderData?.commissionCalculation?.referenceType} profit`)}
                  </PorscheFont>
                </td>
                <td colSpan={5} className="table-td-left pl-220">
                  <PorscheFont>
                    {t(` Standard: ${recalculationOrderData?.commissionCalculation?.referenceValue} % of ${recalculationOrderData?.commissionCalculation?.referenceType} profit`)}
                  </PorscheFont>
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <div style={{ display: "flex" }}>
                    <PorscheFont>{t("PFS commission (net)")}</PorscheFont>
                    {order?.paymentDetails?.leasing_s && (
                      <div
                        className="commission-flag-border"
                        style={{ marginLeft: "12px" }}
                      >
                        <PText size="x-small">S</PText>
                      </div>
                    )}
                    {order?.paymentDetails?.servicePlus && (
                      <div
                        className="commission-flag-border"
                        style={{ marginLeft: "8px" }}
                      >
                        <PText size="x-small">SP</PText>
                      </div>
                    )}
                  </div>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.commissionCalculation?.pfsCommission
                        ? orderData?.commissionCalculation?.pfsCommission
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.commissionCalculation
                        ?.pfsCommission
                        ? recalculationOrderData?.commissionCalculation
                            ?.pfsCommission
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.commissionCalculation?.pfsCommission &&
                    recalculationOrderData?.commissionCalculation
                      ?.pfsCommission &&
                    recalculationOrderData?.commissionCalculation
                      ?.pfsCommission! -
                      orderData?.commissionCalculation?.pfsCommission >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.commissionCalculation
                        ?.pfsCommission ?? 0) -
                        (orderData?.commissionCalculation?.pfsCommission ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Car Policy commission")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.commissionCalculation?.carPolicyCommission
                        ? orderData?.commissionCalculation?.carPolicyCommission
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.commissionCalculation
                        ?.carPolicyCommission
                        ? recalculationOrderData?.commissionCalculation
                            ?.carPolicyCommission
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.commissionCalculation?.carPolicyCommission &&
                    recalculationOrderData?.commissionCalculation
                      ?.carPolicyCommission &&
                    recalculationOrderData?.commissionCalculation
                      ?.carPolicyCommission! -
                      orderData?.commissionCalculation?.carPolicyCommission >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.commissionCalculation
                        ?.carPolicyCommission ?? 0) -
                        (orderData?.commissionCalculation
                          ?.carPolicyCommission ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Incentive / Bonus")}</PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(
                        orderData?.commissionCalculation?.incentive
                          ? orderData?.commissionCalculation?.incentive
                          : 0
                      )} €`}
                    </PorscheFont>
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.commissionCalculation?.incentive
                        ? recalculationOrderData?.commissionCalculation
                            ?.incentive
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.commissionCalculation?.incentive &&
                    recalculationOrderData?.commissionCalculation?.incentive &&
                    recalculationOrderData?.commissionCalculation?.incentive! -
                      orderData?.commissionCalculation?.incentive >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.commissionCalculation
                        ?.incentive ?? 0) -
                        (orderData?.commissionCalculation?.incentive ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>{t("Incentive Program")}</PorscheFont>
                </td>
                <td colSpan={8} className="table-td-left comment-col">
                  <PorscheFont>
                    {orderData?.commissionCalculation &&
                      orderData?.commissionCalculation?.incentiveProgram}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-td-left">
                  <PorscheFont>
                    {t("PDS/TEQ commission (not included)")}
                  </PorscheFont>
                </td>
                <td className="table-td">
                  <PorscheFont>
                    <PorscheFont>
                      {`${formatCurrencyWithNoUnit(upsalseTotalCommission)} €`}
                    </PorscheFont>
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td></td>
                <td className="table-td">
                  <PorscheFont>
                    <PorscheFont>
                    {`${formatCurrencyWithNoUnit(upsalseTotalCommission)} €`}
                    </PorscheFont>
                  </PorscheFont>
                </td>
                <td className="table-td"></td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.commissionCalculation?.incentive &&
                    recalculationOrderData?.commissionCalculation?.incentive &&
                    recalculationOrderData?.commissionCalculation?.incentive! -
                      orderData?.commissionCalculation?.incentive >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {"0,00 €"}
                    {/* {`${formatCurrencyWithNoUnit((recalculationOrderData?.commissionCalculation?.incentive ?? 0) - (orderData?.commissionCalculation?.incentive ?? 0))} €`} */}
                  </PorscheFont>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-col-bold text-left">
                  <PorscheFont>{t("Total commission")}</PorscheFont>
                </td>
                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      orderData?.commissionCalculation?.totalCommission
                        ? negativeValueHandler(orderData?.commissionCalculation?.totalCommission) 
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td></td>

                <td className="table-col-bold text-right">
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      recalculationOrderData?.commissionCalculation
                        ?.totalCommission
                        ? negativeValueHandler(recalculationOrderData?.commissionCalculation
                          ?.totalCommission) 
                        : 0
                    )} €`}
                  </PorscheFont>
                </td>
                <td></td>
                <td></td>
                <td
                  className={`table-${
                    orderData?.commissionCalculation?.totalCommission &&
                    recalculationOrderData?.commissionCalculation
                      ?.totalCommission &&
                    recalculationOrderData?.commissionCalculation
                      ?.totalCommission! -
                      orderData?.commissionCalculation?.totalCommission >
                      0
                      ? "col-bold text-right"
                      : "td"
                  }`}
                >
                  <PorscheFont>
                    {`${formatCurrencyWithNoUnit(
                      (recalculationOrderData?.commissionCalculation
                        ?.totalCommission ?? 0) -
                        (orderData?.commissionCalculation?.totalCommission ?? 0)
                    )} €`}
                  </PorscheFont>
                </td>
              </tr>
            </tbody>
            {/* Commission calculation  In end */}

            {/* Commission sharing Start */}
            <tbody>
              <tr>
                <td className="table-col-bold table-th-border-bottom p-top">
                  <PorscheFont>{t("Commission sharing")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top custom-header">
                  <PorscheFont>{t("Sellers involved")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Share in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Share in €")}</PorscheFont>
                </td>
                <td
                  colSpan={3}
                  className="table-th-border-bottom p-top table-td"
                ></td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Share in %")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Share in €")}</PorscheFont>
                </td>
                <td className="table-th-border-bottom p-top table-td">
                  <PorscheFont>{t("Deviation")}</PorscheFont>
                </td>
              </tr>
              {orderData?.commissionSharing?.map((ele, i) => {
                return (
                  <tr key={i}>
                    {i === 0 && (
                      <td className="table-td-left">
                        <PorscheFont>{t("Vehicle commission")}</PorscheFont>
                      </td>
                    )}
                    {i === 2 && (
                      <td className="table-td-left">
                        {/* <PorscheFont>{t("Vehicle commission")}</PorscheFont> */}
                      </td>
                    )}
                    {i === 1 && (
                      <td className="table-td-left">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            orderData?.commissionCalculation
                              ?.vehicleCommissionEuro
                              ? orderData?.commissionCalculation
                                  ?.vehicleCommissionEuro
                              : 0
                          )} €`}
                        </PorscheFont>
                      </td>
                    )}
                    <td className="table-td-left">
                      <PorscheFont>
                        {ele.sellerNames?.ppnName
                          ? ele.sellerNames?.ppnName
                          : "test"}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.shareInPercent)} %`}
                      </PorscheFont>
                    </td>
                    <td className="table-td">
                      <PorscheFont>
                        {`${formatCurrencyWithNoUnit(ele.shareInEuro)} €`}
                      </PorscheFont>
                    </td>
                    <td colSpan={2} />
                    {i === 0 && (
                      <td className="table-td">
                        <PorscheFont>{t("Vehicle commission")}</PorscheFont>
                      </td>
                    )}
                    {i===2 && (
                      <td className="table-td">
                      </td>
                    )}
                    {i === 1 && (
                      <td className="table-td">
                        <PorscheFont>
                          {`${formatCurrencyWithNoUnit(
                            recalculationOrderData?.commissionCalculation
                              ?.vehicleCommissionEuro
                              ? recalculationOrderData?.commissionCalculation
                                  ?.vehicleCommissionEuro
                              : 0
                          )} €`}
                        </PorscheFont>
                      </td>
                    )}
                    <td className="table-td">
                      <PorscheFont>
                        {`${
                          recalCommissionShares &&
                          formatCurrencyWithNoUnit(
                            recalCommissionShares[i]?.shareInPercent ?? 0
                          )
                        } %`}
                      </PorscheFont>
                    </td>

                    <td className="table-td">
                      <PorscheFont>
                        {`${
                          recalCommissionShares &&
                          formatCurrencyWithNoUnit(
                            recalCommissionShares[i]?.shareInEuro ?? 0
                          )
                        } €`}
                      </PorscheFont>
                    </td>
                    <td
                      className={`table-${
                        recalCommissionShares &&
                        recalCommissionShares[i]?.shareInEuro &&
                        recalCommissionShares[i]?.shareInEuro! -
                          ele.shareInEuro >
                          0
                          ? "col-bold text-right"
                          : "td"
                      }`}
                    >
                      <PorscheFont>
                        {`${
                          recalCommissionShares &&
                          formatCurrencyWithNoUnit(
                            (recalCommissionShares[i]?.shareInEuro ?? 0) -
                              (ele.shareInEuro ?? 0)
                          )
                        } €`}
                      </PorscheFont>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* Commission sharing End */}
          </table>
        </div>
      </div>
    </>
  );
});
