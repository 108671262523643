import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/system";
import {
  IconName,
  PCheckboxWrapper,
  PGrid,
  PGridItem,
  PIcon,
  PSelectWrapper,
  PTextFieldWrapper,
} from "@porsche-design-system/components-react";

import { PorscheFont } from "../../../../assets/styles/CustomStyles";
import { OrderContext } from "../../../../contexts/OrderContext";
import {
  DeliveryLocation,
  DeliveryPresent,
  OrderDTO,
  Registration,
  RegistrationDto,
  VehicleCategoryDto,
} from "../../../../interfaces/Order";
import { formatCurrency } from "../../../../mock/helper";
import { CustomTextField } from "../../../common/CustomTextField";
import { formatDate } from "../../../../helpers/formatters";

interface Props {
  deliveryLocations: Array<DeliveryLocation>;
  deliveryPresents: Array<DeliveryPresent>;
  registrations: Array<Registration>;
  selectedVehicleCategories: Array<VehicleCategoryDto>;
  enableNextButton: (isValid: boolean) => void;
  tradeInToggle: boolean;
  referralToggle: boolean;
  disabled: boolean;
  deliveryDetails?: OrderDTO["deliveryDetails"];
  handleModified: () => void;
  updateError: (id: string) => void;
}

export const Delivery = (props: Props): JSX.Element => {
  const {
    deliveryLocations,
    deliveryPresents,
    registrations,
    enableNextButton,
    selectedVehicleCategories,
    tradeInToggle,
    referralToggle,
    disabled,
    deliveryDetails,
    handleModified,
    updateError,
  } = props;
    const orderContext = useContext(OrderContext);
  const errors = orderContext.getErrors();
  const { t } = useTranslation();
  const [exportDeal, setExportDeal] = useState<boolean>(false);
    const [locationValues, setLocationValues] = useState<Array<DeliveryLocation>>(
    []
  );
      const [registrationValues, setRegistrationValues] = useState<
    Array<Registration>
  >([]);
  const [plannedDelivery, setPlannedDelivery] = useState("");
  const [showWarning, setWarning] = useState<boolean>(false);
  const [showLicensePlate, setLicensePlate] = useState<boolean>(false);
  const [registrationId, setRegistrationId] = useState<number>(0);
  const [registrationType, setRegistrationType] = useState("");
  const [reload, setReload] = useState("");
  const [initialLocationValues, setInitialLocationValues] =
    useState<boolean>(false);
  const [disabledClassName, setDisabledClassName] = useState("");
  const [locationId, setLocationId] = useState<number>(0);
  const [dateOfOrder, setDateOfOrder] = useState("");
  
  

  useEffect(() => {
        if (deliveryDetails) {
            registerRegistrationValues();
      setLicensePlate(true);
      setRegistrationId(
        deliveryDetails?.registrationDto
          ? deliveryDetails?.registrationDto?.id
          : 0
      );
      setRegistrationType(
        deliveryDetails?.registrationDto &&
          deliveryDetails?.registrationDto?.registrationType
          ? deliveryDetails?.registrationDto?.registrationType
          : ""
      );
      setExportDeal(deliveryDetails?.exportDeal);
            let values = deliveryLocations.filter(
        (deliveryLocation) =>
          deliveryLocation.exportDeal === deliveryDetails.exportDeal
      );
            setLocationValues(values);
            if (deliveryDetails.deliveryLocation)
        setLocationId(deliveryDetails.deliveryLocation.id);

      if (deliveryDetails) {
        if (disabled) {
          setDisabledClassName("disabled");
        } else {
          setDisabledClassName("");
        }
      }
      if (deliveryDetails.registrationDto?.id) {
        setRegistrationId(deliveryDetails.registrationDto.id);
        setRegistrationType(
          deliveryDetails?.registrationDto?.registrationType
            ? deliveryDetails?.registrationDto?.registrationType
            : ""
        );
      } else {
        setDisabledClassName("");
      }
      if (deliveryDetails?.plannedDelivery) {
        setPlannedDelivery(
          new Date(deliveryDetails?.plannedDelivery).toISOString().split("T")[0]
        );
        //setPlannedDelivery(new Date(deliveryDetails?.plannedDelivery).toLocaleDateString("de-DE"));
        setReload(new Date().toLocaleDateString());
      }
    }
  }, [deliveryDetails, disabled]);

  useEffect(() => {}, [registrationId, errors, reload]);

  useEffect(() => {
    let isValid = isValidDeliveryDetailsSection();
    orderContext.updateFormValidation(0, isValid);
    enableNextButton(isValid);
  });

  // useEffect(() => {
  //     let values = deliveryLocations.filter(deliveryLocation => deliveryLocation.exportDeal === false)
  //     setLocationValues(values)
  //     setInitialLocationValues(true)
  // },[deliveryLocations, initialLocationValues])

  useEffect(() => {
  if(exportDeal == true){
    let values = deliveryLocations?.filter(
      (deliveryLocation) => deliveryLocation.exportDeal === exportDeal
    );
      setLocationValues(values);
  } else {
      let values = deliveryLocations?.filter(
        (deliveryLocation) =>
          deliveryLocation.exportDeal === false
      );
      setLocationValues(values);
      //setLocationValues(deliveryLocations);
    };
    
    setReload(new Date().toLocaleDateString());
  }, [exportDeal, deliveryLocations]);

  useEffect(() => {
    registerRegistrationValues();
  }, [selectedVehicleCategories]);

  const registerRegistrationValues = () => {
    if (selectedVehicleCategories && selectedVehicleCategories?.length > 0) {
      let type = selectedVehicleCategories[0].id;
      const inclusiveOptions = [
        "15 Days incl. Customs and MRN",
        "30 Days incl. Customs and MRN",
      ];
      let values = registrations?.filter(
        (element) =>
          inclusiveOptions?.findIndex(
            (crit) => element.registrationType === crit
          ) < 0 || type < 4
      );
      const inclusiveMrnOptions = ["EUR1 Creation", "MRN and EUR1 Creation"];
      values = values.filter(
        (element) =>
          inclusiveMrnOptions?.findIndex(
            (crit) => element.registrationType === crit
          ) < 0 || type < 4
      );
      setRegistrationValues(values);
    } else {
      setRegistrationValues(registrations);
    }
  };

  const isValidDeliveryDetailsSection = (): boolean => {
    const errors = orderContext.getErrors();
    return errors.every((error) => {
      if (error.section === "vehicleDetails") return false;
      if (error.section === "customerDetails") return false;
      if (error.section === "paymentDetails") return false;
      if (error.section === "deliveryDetails") return false;
      if (tradeInToggle && error.section === "tradeInDetails") return false;
      if (referralToggle && error.section === "deliveryDetails") return false;

      return true;
    });
  };

  const updateDeliveryDetails = (
    deliveryDetails: OrderDTO["deliveryDetails"]
  ) => {
    let orderDto = getOrderDetails();
    orderDto["deliveryDetails"] = deliveryDetails;
    orderContext.updateCtxOrderDetails(orderDto);
  };

  const getOrderDetails = (): OrderDTO => {
    return orderContext.getCtxOrderDetails();
  };

  const getDeliveryDetailsFromOrder = (
    orderDto: OrderDTO
  ): OrderDTO["deliveryDetails"] => {
    return orderDto["deliveryDetails"] || {};
  };

  const handleExportDeal = () => {
    setExportDeal(!exportDeal);
    let deliveryDetails = getDeliveryDetailsFromOrder(getOrderDetails());
    deliveryDetails.exportDeal = !exportDeal;
    updateDeliveryDetails(deliveryDetails);
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleDeliveryLocation = (e: ChangeEvent<HTMLSelectElement>): void => {
    let ord = getOrderDetails();
    let deliveryDetails = getDeliveryDetailsFromOrder(ord);
    if (e.target.value === "default") {
      orderContext.setErrors({
        field: "deliveryLocation",
        message: "",
        section: "deliveryDetails",
      });
      deliveryDetails.deliveryLocation = null;
      updateDeliveryDetails(deliveryDetails);
      setReload(new Date().toLocaleString());
      return;
    } else {
    }
    orderContext.removeError("paymentDetails");
    orderContext.removeError("deliveryDetails");

    let deliveryLocation = deliveryLocations.filter(
      (location) => location.id === parseInt(e.target.value)
    );
    // Reset additional costs on change of delivery location
    let cdd = orderContext.getCtxCalculationDraftDetails(
      ord?.vehicleDetails?.category?.type
    );
    if (cdd.calculationType !== undefined) {
      if (
        cdd.additionalCostsPrices &&
        cdd.additionalCostsPrices[0].label !== deliveryLocation[0].name
      ) {
        let add = cdd.additionalCostsPrices;
        add[0].amountNet = 0.0;
        add[0].customerShare = 0.0;
        add[0].discountInEuro = 0.0;
        add[0].discountInPercent = 0.0;
        cdd.additionalCostsPrices = add;
        orderContext.updateCtxCalculationDraftDetails(
          cdd,
          ord?.vehicleDetails?.category?.type
        );
      }
    }
    deliveryDetails.deliveryLocation = deliveryLocation[0];
    
    updateDeliveryDetails(deliveryDetails);
        updateError("deliveryLocation");
    setReload(new Date().toLocaleString());
    orderContext.removeError("deliveryLocation");
    handleModified();
    setReload(new Date().toLocaleString());
    handleMandatoryFields();
  };
  const handleMandatoryFields = () => {
    orderContext.removeError("paymentDetails");
    orderContext.removeError("customerDetails");
    orderContext.removeError("vehicleDetails");
    const order = orderContext.getCtxOrderDetails();

    if (order.deliveryDetails.plannedDelivery === "default")
      orderContext.setErrors({
        field: "plannedDelivery",
        message: "",
        section: "deliveryDetails",
      });
    if (!order.deliveryDetails.deliveryPresentDto)
      orderContext.setErrors({
        field: "deliveryPresent",
        message: "",
        section: "deliveryDetails",
      });
    if (!order.deliveryDetails.registrationDto)
      orderContext.setErrors({
        field: "registration",
        message: "",
        section: "deliveryDetails",
      });
  };

  const handleRegistration = (e: ChangeEvent<HTMLSelectElement>): void => {
    if (e.target.value === "default") {
      setLicensePlate(false);

      orderContext.setErrors({
        field: "registration",
        message: "",
        section: "deliveryDetails",
      });
      setReload(new Date().toLocaleString());
      let deliveryDetails = getDeliveryDetailsFromOrder(getOrderDetails());
      deliveryDetails.preferredLicensePlate = null;
      deliveryDetails.registrationDto = null;
      updateDeliveryDetails(deliveryDetails);
      return;
    }
    

    const filteredRegistration = registrations.filter(
      (registration) => registration.id === parseInt(e.target.value)
    );
    const selectedRegistration = filteredRegistration[0];
    let deliveryDetails = getDeliveryDetailsFromOrder(getOrderDetails());
    let registrationDto = deliveryDetails.registrationDto || null;
    registrationDto = selectedRegistration;
    deliveryDetails.registrationDto = registrationDto;
    
    updateDeliveryDetails(deliveryDetails);
    updateError("registration");
    let ord = getOrderDetails();
    let cdd = orderContext.getCtxCalculationDraftDetails(
      ord?.vehicleDetails?.category?.type
    );
    if (cdd.calculationType !== undefined) {
      if (
        cdd.additionalCostsPrices !== undefined &&
        cdd.additionalCostsPrices[1].label !==
          registrationDto.label + " registration"
      ) {
        let add = cdd.additionalCostsPrices;
        add[1].amountNet = 0.0;
        add[1].customerShare = 0.0;
        add[1].discountInEuro = 0.0;
        add[1].discountInPercent = 0.0;
        cdd.additionalCostsPrices = add;
        orderContext.updateCtxCalculationDraftDetails(
          cdd,
          ord?.vehicleDetails?.category?.type
        );
      }
    }
    setRegistrationId(registrationDto?.id);
    setRegistrationType(
      registrationDto?.registrationType ? registrationDto?.registrationType : ""
    );
    
    setLicensePlate(true);
    orderContext.removeError("registration");
    handleModified();
    // 
    setReload(new Date().toLocaleString());

    if (parseInt(e.target.value) === 1) {
      orderContext.setErrors({
        field: "licensePlate",
        message: "",
        section: "deliveryDetails",
      });
    } else {
      orderContext.removeError("licensePlate");
    }
  };

  const handleDeliveryPresent = (e: ChangeEvent<HTMLSelectElement>): void => {
    let deliveryDetails = getDeliveryDetailsFromOrder(getOrderDetails());
    let deliveryPresentDto = deliveryDetails.deliveryPresentDto || null;
    if (e.target.value === "default") {
      deliveryDetails.deliveryPresentDto = undefined;
      updateDeliveryDetails(deliveryDetails);
      orderContext.setErrors({
        field: "deliveryPresent",
        message: "",
        section: "deliveryDetails",
      });
      setReload(new Date().toLocaleString());
      return;
    }

    if (e.target.value === "null") {
      deliveryDetails.deliveryPresentDto = null;
      updateDeliveryDetails(deliveryDetails);
      updateError("deliveryPresent");
      setReload(new Date().toLocaleString());
      return;
    }

    const filteredDeliveryPresents = deliveryPresents.filter(
      (deliveryPresent) => deliveryPresent.id === parseInt(e.target.value)
    );
    const selectedDeliveryPresent = filteredDeliveryPresents[0];

    deliveryPresentDto = selectedDeliveryPresent;
    deliveryDetails.deliveryPresentDto = deliveryPresentDto;
    updateDeliveryDetails(deliveryDetails);
    updateError("deliveryPresent");
    orderContext.removeError("deliveryPresent");
    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handleLicensePlate = (e: ChangeEvent<HTMLInputElement>): void => {
    let deliveryDetails = getDeliveryDetailsFromOrder(getOrderDetails());
    if (e.target.value.length > 0) {
      deliveryDetails.preferredLicensePlate = e.target.value;
      updateDeliveryDetails(deliveryDetails);
      updateError("dLicensePlate");
      handleModified();
      setReload(new Date().toLocaleString());
      
      orderContext.removeError("licensePlate");
    } else {
      deliveryDetails.preferredLicensePlate = null;
      updateDeliveryDetails(deliveryDetails);
    }
  };

  const handleRequiredLicensePlate = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    let deliveryDetails = getDeliveryDetailsFromOrder(getOrderDetails());
    if (e.target.value.length >= 0) {
      deliveryDetails.preferredLicensePlate = e.target.value;
      updateDeliveryDetails(deliveryDetails);
      updateError("dLicensePlate");
      
      orderContext.removeError("licensePlate");
    } else {
      deliveryDetails.preferredLicensePlate = null;
      updateDeliveryDetails(deliveryDetails);
      orderContext.setErrors({
        field: "licensePlate",
        message: "",
        section: "deliveryDetails",
      });
    }

    handleModified();
    setReload(new Date().toLocaleString());
  };

  const handlePlannedDelivery = (e: ChangeEvent<HTMLInputElement>): void => {
    let deliveryDetails = getDeliveryDetailsFromOrder(getOrderDetails());
    if (e.target.value.length) {
      setPlannedDelivery(e.target.value);
      deliveryDetails.plannedDelivery = e.target.value;
      updateDeliveryDetails(deliveryDetails);
      updateError("plannedDelivery");
      const enteredDate = new Date(e.target.value);
      const now = new Date();
      const msScheduledDates = Math.abs(enteredDate.getTime() - now.getTime());
      const daysBetweenScheduledDates =
        msScheduledDates / (24 * 60 * 60 * 1000);
      setWarning(daysBetweenScheduledDates < 5);
      handleModified();
      setReload(new Date().toLocaleString());
      orderContext.removeError("plannedDelivery");
    } else {
      setPlannedDelivery("");
      deliveryDetails.plannedDelivery = "";
      updateDeliveryDetails(deliveryDetails);
      setReload(new Date().toLocaleString());
      orderContext.setErrors({
        field: "plannedDelivery",
        message: "",
        section: "deliveryDetails",
      });
    }
  };

  return (
    <>
      {/* <div style={{backgroundColor: "white"}}>       
          <PorscheFont data-testid="delivery" className="card-header">{t("Delivery")}</PorscheFont>      
            <PGrid>
                <PGridItem size={12} style={{paddingBottom:"10px"}}>
                    <PCheckboxWrapper label="Export Deal" hideLabel={false}>
                    <input
                        type="checkbox"
                        name="exportDeal"
                        checked={exportDeal ? true : false}
                        onClick={handleExportDeal}
                        disabled={disabled}
                    />
                </PCheckboxWrapper>
                </PGridItem>
                <PGridItem size={3}>
                    <PSelectWrapper label="Delivery location">
                    <select name="location_id" onChange={(e) => handleDeliveryLocation(e)} required disabled={disabled}>
                    <option key="default" value="default">Select delivery location</option>
                        { locationValues?.map((location, index) => {
                            return <option key={index} value={location.id} selected={location.id === locationId}>{ location.name }</option>
                        })
                        }
                    </select>
                </PSelectWrapper>
                </PGridItem>
                <PGridItem size={2}>
                <PTextFieldWrapper label="Planned delivery">
                    <input type="date" name="plannedDelivery" value={plannedDelivery} required onChange={handlePlannedDelivery} disabled={disabled} />
                </PTextFieldWrapper>
                </PGridItem>
                <PGridItem size={3}>
                    <PSelectWrapper label={`${t("Delivery present")}`}>
                        <select name="delivery_present_id" onChange={(e) => handleDeliveryPresent(e)} required disabled={disabled}>
                        <option key="default" value="default">{t("Select delivery present")}</option>
                            { deliveryPresents.map((present, index) => {
                                if((orderContext.getCtxOrderDetails()?.vehicleDetails?.category?.type === "DemoCar" ||
                                  orderContext.getCtxOrderDetails()?.vehicleDetails?.category?.type === "UsedCar") &&
                                  present.deliveryPresentType === "UsedCarDemoCar") {
                                    return(
                                        <option key={index} value={present.id} selected={present.id === deliveryDetails?.deliveryPresentDto?.id}>
                                        { `${present.presentName} | ${formatCurrency(present.sellingPriceFrom)} - ${formatCurrency(present.sellingPrinceTo)}`}
                                        </option>
                                    )
                                }

                                if(orderContext.getCtxOrderDetails()?.vehicleDetails?.category?.type === "NewCar" && 
                                    present.deliveryPresentType === "NewCar") {
                                    return(
                                        <option key={index} value={present.id} selected={present.id === deliveryDetails?.deliveryPresentDto?.id}>
                                        {present.presentName}
                                        </option>
                                    )
                                }
                                })
                            }
                        </select>
                    </PSelectWrapper>
                </PGridItem>
                <PGridItem size={3}>
                    <PSelectWrapper label="Registration">
                        <select name="registrations" onChange={(e) => handleRegistration(e)} required disabled={disabled}>
                        <option key="default" value="default">Select Registration</option>
                            { registrationValues.map((registration, index) => {
                                if (selectedVehicleCategories && selectedVehicleCategories.length > 0) {
                                    if((registration.registrationType === "InclCustomsMRNAndEUR1Days15" || 
                                    registration.registrationType === "InclCustomsMRNAndEUR1Days30" ||
                                    registration.registrationType === "EUR1Creation" ||
                                    registration.registrationType === "MRNAndEUR1Creation") &&
                                    selectedVehicleCategories[0].type !== "NewCar") {
                                        return <></>
                                    }
                                }
                                return <option key={index} value = {registration.id} selected={registration.id === deliveryDetails?.registrationDto?.id}>{registration.label}</option>
                                })
                            }
                        </select>
                    </PSelectWrapper>
                </PGridItem>
                { showLicensePlate &&  (registrationType === "Standard")  && (
                    <PGridItem size={3} style={{marginTop:"-20px"}}>
                        <CustomTextField 
                            type="text" 
                            name="licensePlate" 
                            defaultValue={deliveryDetails?.preferredLicensePlate} 
                            label={ "Preferred license plate" } 
                            onClick={handleRequiredLicensePlate} 
                            onChange={handleRequiredLicensePlate}  
                            required={true} 
                            disabled={disabled}
                            />
                    </PGridItem>
                )}

                { showLicensePlate &&  (registrationType !== "Standard" && registrationType !== "RegistrationByCustomer")  && (
                    <PGridItem size={3} style={{marginTop:"-20px"}}>
                        <CustomTextField type="text" name="licensePlate" defaultValue={deliveryDetails?.preferredLicensePlate} label="Preferred license plate (Optional)" onClick={handleLicensePlate} onChange={handleLicensePlate} disabled={disabled} />
                    </PGridItem>
                )}
                <PGridItem size={3}>
                            <></>
                </PGridItem>
                <PGridItem size={9}>
                            { showWarning && (
                                <span style={{color: "red"}}><PorscheFont className="txt-warning-msg">{t("Very short term! Please consult with dispatch!")}</PorscheFont></span>
                            )}
                </PGridItem>

            </PGrid>

        </div> */}

      <div style={{ backgroundColor: "white" }}>
        <PorscheFont data-testid="delivery" className="card-header">
          {t("Delivery")}
        </PorscheFont>
        <PCheckboxWrapper label={`${t("Export Deal")}`} hideLabel={false}>
          <input
            type="checkbox"
            name="exportDeal"
            checked={exportDeal ? true : false}
            onClick={handleExportDeal}
            disabled={disabled}
          />
        </PCheckboxWrapper>
        &ensp;
        <Stack direction="row">
          <div>
            <PSelectWrapper
              id={"deliveryLocationWrap"}
              label={`${t("Delivery location")}`}
            >
              <select
                id={"deliveryLocation"}
                name="location_id"
                onChange={(e) => handleDeliveryLocation(e)}
                required
                disabled={disabled}
                style={{minWidth: "270px"}}
                >
                <option key="default" value="default">
                  {t("Select delivery location")}
                </option>
                {locationValues?.map((location, index) => {
                  return (
                    <option
                      key={index}
                      value={location.id}
                      selected={location.id === locationId}
                    >
                      {t(location.name)}
                    </option>
                  );
                })}
              </select>
            </PSelectWrapper>
          </div>
          &ensp;
          <div style={{ width: "220px" }}>
            <Stack direction="column">
            <PTextFieldWrapper id="plannedDeliveryWrap" label={`${t("Planned delivery")}`}>
              {disabled ? (
                  <input
                      id="plannedDelivery"
                      type="text"
                      name="plannedDelivery"
                      value={plannedDelivery ? formatDate(plannedDelivery) : ""}
                      disabled
                  />
              ) : (
                  <input
                      id="plannedDelivery"
                      aria-autocomplete="none"
                      autoComplete="off"
                      type="date"
                      name="plannedDelivery"
                      value={plannedDelivery || ""}
                      required
                      min={new Date().toISOString().slice(0, 10)}
                      max="9999-12-31"
                      onChange={handlePlannedDelivery}
                      placeholder="dd.mm.yyyy"
                  />
              )}
            </PTextFieldWrapper>

              {/* { showWarning && (
                    <>
                      <span style={{color: "red"}}><PorscheFont className="txt-warning-msg">{t("Very short term!Please consult with dispatch!")}</PorscheFont></span>
                    </>
                )} */}
            </Stack>
          </div>
          &ensp;
          <div>
            <PSelectWrapper
              id={"deliveryPresentWrap"}
              label={`${t("Delivery present")}`}
            >
              <select
                id={"deliveryPresent"}
                name="delivery_present_id"
                onChange={(e) => handleDeliveryPresent(e)}
                required
                disabled={disabled}
              >
                <option key="default" value="default" selected>
                  {t("Select Delivery Present")}
                </option>
                <option
                  value="null"
                  selected={
                    deliveryDetails?.deliveryPresentDto === null ? true : false
                  }
                >
                  {t("None")}
                </option>
                {deliveryPresents?.map((present, index) => {
                  if (
                    (orderContext.getCtxOrderDetails()?.vehicleDetails?.category
                      ?.type === "DemoCar" ||
                      orderContext.getCtxOrderDetails()?.vehicleDetails
                        ?.category?.type === "UsedCar") &&
                    present.deliveryPresentType === "UsedCarDemoCar"
                  ) {
                    return (
                      <option
                        key={index}
                        value={present.id}
                        selected={
                          present.id === deliveryDetails?.deliveryPresentDto?.id
                        }
                      >
                        {`${present.presentName} | ${formatCurrency(
                          present.sellingPriceFrom
                        )} - ${formatCurrency(present.sellingPrinceTo)}`}
                      </option>
                    );
                  }

                  if (
                    orderContext.getCtxOrderDetails()?.vehicleDetails?.category
                      ?.type === "NewCar" &&
                    present.deliveryPresentType === "NewCar"
                  ) {
                    if (
                      orderContext.getCtxOrderDetails()?.deliveryDetails
                        ?.deliveryLocation?.locationType ===
                      present?.locations[0]
                    ) {
                      return (
                        <option
                          key={index}
                          value={present.id}
                          selected={
                            present.id ===
                            deliveryDetails?.deliveryPresentDto?.id
                          }
                        >
                          {present.presentName}
                        </option>
                      );
                    } else if (
                      orderContext.getCtxOrderDetails()?.deliveryDetails
                        ?.deliveryLocation?.locationType === "Other"
                    ) {
                      return (
                        <option
                          key={index}
                          value={present.id}
                          selected={
                            present.id ===
                            deliveryDetails?.deliveryPresentDto?.id
                          }
                        >
                          {present.presentName}
                        </option>
                      );
                    }
                  }
                })}
              </select>
            </PSelectWrapper>
          </div>
          &ensp;
          <div>
            <PSelectWrapper
              id={"registrationWrap"}
              label={`${t("Registration")}`}
            >
              <select
                id={"registration"}
                name="registrations"
                onChange={(e) => handleRegistration(e)}
                required
                disabled={disabled}
              >
                <option key="default" value="default">
                  {t("Select Registration")}
                </option>
                {registrationValues?.map((registration, index) => {
                  if (
                    selectedVehicleCategories &&
                    selectedVehicleCategories.length > 0
                  ) {
                    if (
                      (registration.registrationType ===
                        "InclCustomsMRNAndEUR1Days15" ||
                        registration.registrationType ===
                          "InclCustomsMRNAndEUR1Days30" ||
                        registration.registrationType === "EUR1Creation" ||
                        registration.registrationType ===
                          "MRNAndEUR1Creation") &&
                      selectedVehicleCategories[0].type !== "NewCar"
                    ) {
                      return <></>;
                    }
                  }
                  return (
                    <option
                      key={index}
                      value={registration.id}
                      selected={
                        registration.id === deliveryDetails?.registrationDto?.id
                      }
                    >
                      {t(registration.label)}
                    </option>
                  );
                })}
              </select>
            </PSelectWrapper>
          </div>
          &ensp;
          {showLicensePlate && registrationType === "Standard" && (
            <div style={{ marginTop: "-25px" }}>
              <CustomTextField
                id="dLicensePlate"
                type="text"
                name="licensePlate"
                defaultValue={deliveryDetails?.preferredLicensePlate!}
                label={`${t("Preferred license plate")}`}
                // onClick={handleRequiredLicensePlate}
                onChange={handleRequiredLicensePlate}
                required={true}
                disabled={disabled}
              />
            </div>
          )}
          {showLicensePlate &&
            registrationType !== "Standard" &&
            registrationType !== "RegistrationByCustomer" &&
            registrationType !== "default" && (
              <div style={{ marginTop: "-25px" }}>
                <CustomTextField
                  id="dLicensePlate"
                  type="text"
                  name="licensePlate"
                  defaultValue={deliveryDetails?.preferredLicensePlate!}
                  label={`${t("Pref. license plate (Optional)")}`}
                  //    onClick={handleLicensePlate}
                  onChange={handleLicensePlate}
                  disabled={disabled}
                />
              </div>
            )}
        </Stack>
        {showWarning && (
          <>
            <span style={{ color: "#D5001B" }}>
              <PorscheFont className="txt-warning-msg">
                <PIcon name={"information" as IconName} color="brand"></PIcon>{" "}
                {t("Very short term! Please consult with dispatch!")}
              </PorscheFont>
            </span>
          </>
        )}
      </div>
    </>
  );
};
